<template>
  <section class="container p-3 rounded product-page-detailed pb-4 page-full-width">
    <div class="row content-row">
      <div class="col-md-6 col-12 product-image">
        <brief-info
          :name="product.name"
          :code="product.code"
          :text="product.preview_text"
          :brand="product.brand"
        />
      </div>
      <div class="col-md-6 col-12 product-info">
        <template
          v-if="
            product.offers.length && product.offers[0].price_value > 0 && product.active
          "
        >
          <product-price :offer="product.offers[0]" />
          <action-buttons :offer="product.offers[0]" />
        </template>
        <template v-else>
          <ask-for-offer :technical="product.technical_sheet" />
          <ask-for-offer-modal ref="askForOfferModal" />
        </template>
      </div>
      <div
        class="col-lg-12 col-md-12 col-sm-12 remove-padd little-video fit-vids desc-order"
      >
        <div class="produs-content">
          <more-info :description="product.description" />
        </div>
      </div>
      <div class="col-lg-12 product-info-points">
        <info-points :slug="product.slug" :id="product.id" />
      </div>
    </div>
  </section>
</template>

<script>
import BriefInfo from "@/components/product_page/BriefInfo.vue";
// import Carousel from "@/components/product_page/Carousel.vue";
import ProductPrice from "@/components/product_page/ProductPrice.vue";
import ActionButtons from "@/components/product_page/ActionButtons.vue";
// import Specifications from "@/components/product_page/Specifications.vue";
import MoreInfo from "@/components/product_page/MoreInfo.vue";
import AskForOffer from "@/components/product_page/AskForOffer.vue";
import InfoPoints from "@/components/product_page/InfoPoints.vue";
// import SecondoryDescription from "@/components/product_page/SecondoryDescription.vue";
import AskForOfferModal from "@/components/product_page/AskForOfferModal.vue";

export default {
  components: {
    BriefInfo,
    // Carousel,
    ProductPrice,
    ActionButtons,
    // Specifications,
    MoreInfo,
    AskForOffer,
    InfoPoints,
    // SecondoryDescription,
    AskForOfferModal,
  },
  computed: {
    product() {
      return this.$store.getters["shop/product"];
    },
    properties() {
      let new_array = this.product.property.filter((elem) => {
        return elem.code !== "layout";
      });

      return new_array;
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .desc-order {
    order: 3;
  }
}
</style>
