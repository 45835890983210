<template>
  <card-calculator>
    <template v-slot:title>
      <div class="col-12">
        <label class="title">Calculează preț sticla termorezistenta</label>
      </div>
    </template>
    <div class="input-calculator col-12">
      <div class="row">
        <div class="col-12 col-sm-6">
          <dimension
            @dimValue="setHeight"
            :glass="glassHeight"
            :min="minHeight"
            :max="maxHeight"
            :dim="glassHeightDim"
          />
        </div>
        <div class="col-12 col-sm-6">
          <dimension
            @dimValue="setWidth"
            :glass="glassWidth"
            :min="minWidth"
            :max="maxWidth"
            :dim="glassWidthDim"
          />
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <div class="explicatie col-12">
        <span>Dimensiunile se dau în mm! 1cm = 10mm</span>
      </div>
    </template>
  </card-calculator>
</template>

<script>
import Dimension from "@/components/product_page/custom_glass/Dimension.vue";
import CardCalculator from "@/components/product_page/custom_glass/CardCalculator.vue";

export default {
  components: {
    Dimension,
    CardCalculator,
  },
  props: ["images"],
  computed: {
    glassHeight() {
      return {
        title: "INALTIME",
        class: "inaltime",
      };
    },
    glassWidth() {
      return {
        title: "LUNGIME",
        class: "lungime",
      };
    },
    minHeight() {
      let product = this.$store.getters["shop/product"];
      let minHeight = {};
      if (product.layout_property) {
        minHeight = product.layout_property.find((item) => {
          return item.code === "minim-h";
        });
      }
      return parseInt(minHeight.value ? minHeight.value : 50);
    },
    maxHeight() {
      let product = this.$store.getters["shop/product"];
      let maxHeight = {};
      if (product.layout_property) {
        maxHeight = product.layout_property.find((item) => {
          return item.code === "maxim-h";
        });
      }
      return parseInt(maxHeight.value ? maxHeight.value : 2400);
    },
    minWidth() {
      let product = this.$store.getters["shop/product"];
      let minWidth = {};
      if (product.layout_property) {
        minWidth = product.layout_property.find((item) => {
          return item.code === "minim-l";
        });
      }
      return parseInt(minWidth.value ? minWidth.value : 50);
    },
    maxWidth() {
      let product = this.$store.getters["shop/product"];
      let maxWidth = {};
      if (product.layout_property) {
        maxWidth = product.layout_property.find((item) => {
          return item.code === "maxim-l";
        });
      }
      return parseInt(maxWidth.value ? maxWidth.value : 2400);
    },

    glassWidthDim() {
      return this.$store.getters["glass/glassWidthDim"];
    },

    glassHeightDim() {
      return this.$store.getters["glass/glassHeightDim"];
    },
  },
  methods: {
    setHeight(value) {
      this.$store.dispatch("glass/actionGlassHeightDim", value);
    },
    setWidth(value) {
      this.$store.dispatch("glass/actionGlassWidthDim", value);
    },
  },
};
</script>

<style lang="scss" scoped>
$text-color: #121111 !important;

.explicatie {
  color: #ff0000;
  /* font-weight: bold; */
  font-family: "Montserrat Regular";
  width: 100%;
  height: auto;
  line-height: 50px;
}

.options {
  label.title {
    font-size: 22px;
    color: $text-color;
    font-family: Raleway Regular;
    font-weight: normal;
    padding: 15px 0;
  }
}

@media (max-width: 576px) {
  .inaltime {
    margin-bottom: 10px;
  }

  .explicatie {
    line-height: normal;
    margin-top: 10px;
  }
}
</style>
