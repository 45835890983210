<template>
    <div>
        <!-- <pre>{{ stepData }}</pre> -->
        <!-- <pre>modelValue: {{ modelValue }}</pre> -->
        <div class="item" v-for="option in options" :key="option.id">
            <input
                :data-price="option.price_promo > 0 ? option.price_promo : option.price"
                :data-label="option.name"
                :data-parent-id="option.id"
                :id="'step-' + stepID + '-prod-' + option.id"
                :type="stepData.data.type"
                :name="'answer-step-' + stepID"
                :value="option"
                :class="arclass"
                v-model="modelValue"
                @update:modelValue="(value) => SetStep(value)"
                @click="resetStep(option)"
            />
            <label :for="'step-' + stepID + '-prod-' + option.id" class="clearfix">
                <span class="item_thumb">
                    <img :src="option.image" :alt="option.name" class="img-fluid" />
                </span>
                <ribbon-promote v-if="option.popular">Popular</ribbon-promote>
                <span v-if="option.price_promo > 0" class="old-price">{{ option.price.split(".")[0] }} lei</span>
                <span class="item_price">{{ option.price_promo > 0 ? option.price_promo.split(".")[0] : option.price.split(".")[0] }} lei</span>
                <strong>{{ option.name }}</strong>
                <span class="config_item_description">{{ option.description }}</span>
            </label>
        </div>
    </div>
</template>

<script>
import RibbonPromote from "@/components/product_page/custom_hoch/RibbonPromote.vue";
import { useStore } from "vuex";
import { ref, computed } from "vue";

export default {
    components: {
        RibbonPromote,
    },
    props: {
        stepData: {
            type: Object,
            default: () => {},
        },
    },
    setup(props) {
        const { state, commit } = useStore();
        var modelValue = ref([]);
        const options = computed(() => {
            var selectedProducts = state.configurator.selectedProducts;
            let isSelected = selectedProducts.find(
                ({ step }) => step == modelValue.value.step
            );
            if (isSelected) {
                var stepsToPush = [];
                props.stepData.products.forEach((element) => {
                    if (element.id != modelValue.value.id) {
                        stepsToPush.push(element);
                    } else {
                        stepsToPush.push(modelValue.value);
                    }
                });
                return stepsToPush;
            } else {
                resetModelValue;
                return props.stepData.products;
            }
        });
        function resetModelValue() {
            this.modelValue.value = "";
        }
        const stepID = computed(() => {
            return props.stepData.data.id;
        });
        var arclass = computed(() => {
            let cl = props.stepData.data.type;
            cl = cl + " " + props.stepData.data.type + "-" + props.stepData.data.id;
            if (props.stepData.data.required_for_price) {
                cl = cl + " required";
            }
            return cl;
        });

        return {
            arclass,
            modelValue,
            stepID,
            state,
            commit,
            options,
        };
    },
    methods: {
        SetStep(value) {
            this.$store.dispatch("configurator/selectStep", {
                step: this.stepData.data.id,
                value: value,
            }).then(() => {
                let selectedProducts = this.$store.getters["configurator/selectedProducts"];
                this.$store.dispatch("configurator/getConfiguratorAllProducts", selectedProducts);
            });

            let inputElement = document.getElementsByName('answer-step-' + this.stepData.data.id);
            let parentEl = inputElement[0].closest('.group_items');
            if(parentEl.classList.contains("selected") && (!value || value.length == 0)) {
                parentEl.classList.remove("selected");
            } else if(!parentEl.classList.contains("selected")) {
                parentEl.classList.add("selected");
            }
        },
        resetStep(value) {
            if(!this.stepData.data.required_for_price && this.stepData.data.type == "radio") {
                let selectedProducts = this.$store.getters["configurator/selectedProducts"];
                let isSelected = selectedProducts.find(
                    ({ step }) => step == this.stepData.data.id
                );
                if(isSelected) {
                    if(value.id == isSelected.value.id) {
                        this.SetStep('');
                        let radioElement = document.getElementById('step-' + isSelected.step + '-prod-' + isSelected.value.id);
                        radioElement.checked = false;
                    }
                }
            }
        }
    },
};
</script>


<style lang="scss" scoped>
.item input[type="checkbox"]:not(old):checked + label:before,
.item input[type="radio"]:not(old):checked + label:before,
.item input[type="checkbox"]:not(old):checked + span + label:before,
.item input[type="radio"]:not(old):checked + span + label:before {
    content: url("~@/assets/images/configurator/check-select.svg");
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 28px;
    color: #62ba46;
    z-index: 2;
    background-color: #fff;
    width: 28px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    line-height: 1;
    border: 1px solid #fff;
}

.item input[type="checkbox"]:not(old):checked + label,
.item input[type="radio"]:not(old):checked + label,
.item input[type="checkbox"]:not(old):checked + span + label,
.item input[type="radio"]:not(old):checked + span + label {
    display: block;
    border: 1px solid #62ba46;
    -webkit-box-shadow: 0 2px 40px #e0e5f0;
    -moz-box-shadow: 0 2px 40px #e0e5f0;
    box-shadow: 0 2px 40px #e0e5f0;
}

.item {
    position: relative;
    padding-bottom: 15px;
    padding-top: 3px;

    input[type="radio"]:not(old) {
        visibility: hidden;
    }

    input[type="checkbox"],
    input[type="radio"] {
        display: none;
    }

    label {
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        border-radius: 5px;
        background-color: #fff;
        padding: 20px;
        min-height: 150px;
        margin-bottom: 0;
        -webkit-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -moz-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -ms-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -o-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        position: relative;
        border: 1px solid #d9e1e6;
        font-size: 15px;
        font-size: 0.9375rem;

        width: 100%;
        display: inline-block;
        clear: right;
        cursor: default;
        font-weight: bold;
        color: #9d0000;
        padding-right: 5px;

        &:hover {
            -webkit-box-shadow: 0 2px 40px #e0e5f0;
            -moz-box-shadow: 0 2px 40px #e0e5f0;
            box-shadow: 0 2px 40px #e0e5f0;
            -webkit-transform: translateY(-3px);
            -moz-transform: translateY(-3px);
            -ms-transform: translateY(-3px);
            -o-transform: translateY(-3px);
            transform: translateY(-3px);
        }

        .item_thumb {
            display: block;
            float: right;
            width: 120px;
            height: auto;
            overflow: hidden;
            position: relative;
            z-index: 0;
            margin: 0 0 10px 10px;

            img {
                width: 150px;
                height: auto;
            }
        }

        .item_price {
            display: inline-block;
            font-size: 18px;
            font-family: Raleway Bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #9c1111;
            line-height: 25px;
        }

        strong {
            text-transform: uppercase;
            display: block;
            font-size: 18px;
            color: #333;
            font-family: Raleway Regular;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #121111;
            line-height: 1.1;
            margin-bottom: 10px;
        }

        .config_item_description {
            font-size: 11px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 14px;
            letter-spacing: normal;
            color: #766f6f;
            display: block;
        }
    }
}

@media (max-width: 767px) {
    .item label .item_thumb {
        width: 60px;
    }
}

@media (max-width: 576px) {
    .item label .item_price {
        font-size: 16px;
    }

    .item label strong {
        font-size: 14px;
    }
}
</style>
