<template>
    <input 
        type="radio" 
        :name="name" 
        :value="value" 
        :id="id" 
        required 
        :data-name="dataName"
        @change="onChange($event)"
    >
    <slot></slot>
</template>

<script>
export default {
    props: ['name','value','id','dataName'],
    computed: {
        doorPosition() {
            return this.$store.getters['door/doorPosition'];
        },

        doorType() {
            return this.$store.getters['door/doorType'];
        },
        
        isPositionSelected() {
            return this.$store.getters['door/isPositionSelected'];
        },

        isTypeSelected() {
            return this.$store.getters['door/isTypeSelected'];
        }, 
     
        images() {
            return this.$store.getters['door/images'];
        }

    },

    methods: {
        onChange(event) {
            let radioValue = event.target.value;
            let radioName = event.target.attributes.name.value;

            const door = {
                doorPosition: this.doorPosition,
                doorType: this.doorType,
                isPositionSelected: this.isPositionSelected,
                isTypeSelected: this.isTypeSel,
                images: {}, 
            };

            if (radioName === "options[side-close]") {
                door.doorPosition = radioValue;
                door.isPositionSelected = true;   
            } else if (radioName === "options[door-type]") {
                door.doorType = radioValue;
                door.isTypeSelected = true;
            }


            if (door.doorPosition === 'right' && door.doorType === 'inner') {
                door.images.sketchImage = this.images.sketchRightImage;
                door.images.threeDImage = this.images.threeDRightImage;
            } else if (door.doorPosition === 'left' && door.doorType === 'inner') {
                door.images.sketchImage = this.images.sketchLeftImage;
                door.images.threeDImage = this.images.threeDLeftImage;
            } else if (door.doorPosition === 'right' && door.doorType === 'outer') {
                door.images.sketchImage = this.images.sketchOuterRightImage;
                door.images.threeDImage = this.images.threeDOuterRightImage;
            } else if (door.doorPosition === 'left' && door.doorType === 'outer') {
                door.images.sketchImage = this.images.sketchOuterLeftImage;
                door.images.threeDImage = this.images.threeDOuterLeftImage;
            } 

            this.$store.dispatch('door/actionDoorPosition', door.doorPosition);
            this.$store.dispatch('door/actionDoorType', door.doorType);
            this.$store.dispatch('door/actionIsPositionSelected', door.isPositionSelected);
            this.$store.dispatch('door/actionIsTypeSelected', door.isTypeSelected);
            this.$store.dispatch('door/actionImages', door.images);
            
        }, 
    }
}
</script>