<template>
    <div>
        <div class="step-desctiprion" v-html="description"></div>
        <div class="item add_bottom_30">
            <input
                :id="'step-' + stepID"
                :type="stepData.data.type"
                :name="'answer-step-' + stepID"
                :class="arclass"
                v-model="modelValue"
                @update:modelValue="(value) => SetStep(value)"
            />
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";

export default {
    components: {},
    data() {
        return {
            timer: null,
        };
    },
    props: {
        stepData: {
            type: Object,
            default: () => {},
        },
    },
    setup(props) {
        const { state, commit } = useStore();
        var modelValue = ref([]);
        if(props.stepData.products.length) {
            modelValue = computed(() => {
                return props.stepData.products[0].name;
            });
        }
        var description = computed(() => {
            return props.stepData.data.description;
        });
        const stepID = computed(() => {
            return props.stepData.data.id;
        });
        var arclass = computed(() => {
            let cl = props.stepData.data.type;
            cl = cl + " " + props.stepData.data.type + "-" + props.stepData.data.id;
            if (props.stepData.data.required_for_price) {
                cl = cl + " required";
            }
            return cl;
        });

        return {
            description,
            arclass,
            modelValue,
            stepID,
            state,
            commit,
        };
    },
    methods: {
        SetStep(value) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            var that = this;
            this.timer = setTimeout(function() {
                if (value.length > 3) {
                    that.$store.dispatch("configurator/selectStep", {
                        step: that.stepData.data.id,
                        value: value,
                    });
                }
             }, 800);
        },
    },
};
</script>

<style lang="scss" scoped>
.step-desctiprion {
    font-size: 14px;
}
.item input {
    border-radius: 0;
    height: 40px;
    background-color: #ffffff;
    border: solid 1px rgb(204, 204, 204);
}

.item input[type="checkbox"]:not(old):checked + label:before,
.item input[type="radio"]:not(old):checked + label:before,
.item input[type="checkbox"]:not(old):checked + span + label:before,
.item input[type="radio"]:not(old):checked + span + label:before {
    font-family: Raleway Regular;
    content: "\e81b";
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 28px;
    /* font-size: 1.75rem; */
    color: #62ba46;
    z-index: 2;
    background-color: #fff;
    width: 28px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    line-height: 1;
    border: 1px solid #fff;
}

.item input[type="checkbox"]:not(old):checked + label,
.item input[type="radio"]:not(old):checked + label,
.item input[type="checkbox"]:not(old):checked + span + label,
.item input[type="radio"]:not(old):checked + span + label {
    display: block;
    border: 1px solid #62ba46;
    -webkit-box-shadow: 0 2px 40px #e0e5f0;
    -moz-box-shadow: 0 2px 40px #e0e5f0;
    box-shadow: 0 2px 40px #e0e5f0;
}

.item {
    position: relative;
    padding-bottom: 15px;
    padding-top: 3px;

    input[type="radio"]:not(old) {
        visibility: hidden;
    }

    input[type="checkbox"],
    input[type="radio"] {
        display: none;
    }

    label {
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        border-radius: 5px;
        background-color: #fff;
        padding: 30px;
        min-height: 150px;
        margin-bottom: 0;
        -webkit-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -moz-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -ms-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -o-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        position: relative;
        border: 1px solid #d9e1e6;
        font-size: 15px;
        font-size: 0.9375rem;

        width: 100%;
        display: inline-block;
        clear: right;
        cursor: default;
        font-weight: bold;
        color: #9d0000;
        padding-right: 5px;

        &:hover {
            -webkit-box-shadow: 0 2px 40px #e0e5f0;
            -moz-box-shadow: 0 2px 40px #e0e5f0;
            box-shadow: 0 2px 40px #e0e5f0;
            -webkit-transform: translateY(-3px);
            -moz-transform: translateY(-3px);
            -ms-transform: translateY(-3px);
            -o-transform: translateY(-3px);
            transform: translateY(-3px);
        }

        .item_thumb {
            display: block;
            float: right;
            width: 120px;
            height: auto;
            overflow: hidden;
            position: relative;
            z-index: 0;
            margin: 0 0 0 25px;

            img {
                width: 150px;
                height: auto;
            }
        }

        .item_price {
            display: inline-block;
            color: #f26522;
            font-weight: bold;
            font-size: 18px;
            // font-size: 1.8rem;
            font-family: Raleway Regular;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #9c1111;
        }

        strong {
            text-transform: uppercase;
            display: block;
            font-size: 18px;
            // font-size: 1.8rem;
            color: #333;
            font-family: Raleway Regular;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #121111;
        }

        .config_item_description {
            font-size: 11px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 14px;
            letter-spacing: normal;
            color: #766f6f;
            display: block;
        }
    }
}
</style>
