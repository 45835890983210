<template>
  <section v-if="product.name" class="container p-3 rounded product-page-detailed pb-4 cos-hoch">
    <custom-hoch-card />
  </section>
</template>

<script>
import CustomHochCard from "@/components/product_page/custom_hoch/CustomHochCard.vue";

export default {
  components: {
    CustomHochCard,
  },

  computed: {
    product() {
      // console.log(this.$store.getters['shop/product']);
      return this.$store.getters["shop/product"];
    },
  },
};
</script>

<style>
  .cos-hoch .product-detailed__carousel.carousel {
    margin-bottom: 82px !important;
  }

  @media (min-width: 991px) {
    .cos-hoch .thumb-gallery {
      bottom: 103px !important;
      left: -103px !important;
    }
  }

  @media (min-width: 1200px) {
    .cos-hoch .thumb-gallery {
      bottom: 141px !important;
      left: -141px !important;
    }
  }

  @media (max-width: 768px) {
    .cos-hoch a#readmore {
      left: 15px !important;
      bottom: 0 !important;
    }
  }

</style>
