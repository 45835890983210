<template>
    <div class="panel">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>

#configurator-wrap .panel {
    padding: 0 18px 0 0;
    background-color: transparent;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    margin-bottom: 0;
    border: none;
}

input[type=checkbox], input[type=radio] {
        display: none;
    }

</style>
