<template>
  <div class="d-flex" :class="glass.class">
    <div class="cell">
      {{ glass.title }}
      <div class="error-dim"></div>
    </div>
    <div class="cell calculator_field" :class="glass.class">
      <input
        type="text"
        class="field numeric"
        :name="glass.class"
        :id="glass.class"
        :value="dim"
        maxlength="4"
        required
        data
        v-on:keyup="setDim($event)"
      />
      <span>mm</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["glass", "min", "max", "dim"],
  emits: ["dimValue"],
  methods: {
    isValidDimension(event) {
      let intervalTextContent = document.querySelector(`.${this.glass.class} .error-dim`);
      const dimension = event.target.value;

      if (dimension === "") {
        intervalTextContent.style.display = "none";
        return false;
      }

      if (dimension < this.min) {
        intervalTextContent.style.display = "block";
        intervalTextContent.textContent = `Minim ${this.min} mm`;
      } else if (dimension > this.max) {
        intervalTextContent.style.display = "block";
        intervalTextContent.textContent = `Maxim ${this.max} mm`;
      } else {
        intervalTextContent.style.display = "none";
        return true;
      }
      return false;
    },

    setDim(event) {
      if (this.isValidDimension(event)) {
        this.$emit("dimValue", event.target.value);
      } else {
        this.$emit("dimValue", 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.calculator-sticla {
  .cell {
    width: 85px;
    color: #3c3f48;
    font-size: 18px;
    line-height: 48px;
    height: 50px;
    position: relative;

    &:first-child {
      background-color: #3c3f48;
      color: #fff;
      font-size: 14px;
      font-family: "Raleway";
      text-align: center;
      width: 105px;
    }

    input {
      width: 100%;
      text-align: left;
      padding-left: 10px;
      font-size: 14px;
      height: 100%;
      border: 1px solid #3c3f48;
      font-family: "Montserrat Regular";

      &:focus {
        outline: 0;
      }
    }

    span {
      position: absolute;
      top: 2px;
      right: 5px;
      font-family: "Montserrat Regular";
      font-size: 14px;
    }
  }
}

.error-dim {
  position: absolute;
  top: 0;
  background: #9d0000;
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  display: none;
}
</style>
