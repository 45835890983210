<template>
    <div class="dor-side row">
        <div class="col-12">
            <label class="title">2. Poziție balamale</label>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-6 col-md-4">
                    <custom-door-radio-card 
                        :name="'options[side-close]'"
                        :value="'left'"
                        :id="'side-left'"
                        :data-name="'Stanga'" 
                    >
                        <label for="side-left" class="calc-options-label">Stânga</label>
                    </custom-door-radio-card>
                    
                </div>
                <div class="col-6 col-md-4">
                    <custom-door-radio-card
                        :name="'options[side-close]'"
                        :value="'right'"
                        :id="'side-right'"
                        :data-name="'Dreapta'"  
                    >
                        <label for="side-right" class="calc-options-label">Dreapta</label>
                    </custom-door-radio-card>
                    
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import CustomDoorRadioCard from '@/components/product_page/custom_door/CustomDoorRadioCard.vue';
export default {
    components: {
        CustomDoorRadioCard
    },
}
</script>