<template>
    <div id="bottom-wizard">
        <div class="container-fluid">
            <div
                class="row no-gutters price-conf-wrap disable"
                v-bind:class="{ 'no-price': allRequired == false }"
            >
                <div id="price_total" class="produs-pret col-lg-12" :class="allRequired ? 'col-6' : 'col-8'">
                    <div v-if="allRequired == false" class="old_value starting-with">Prețuri începând cu</div>
                    <div v-if="price.total_promo > 0" class="old_value">PRET vechi: {{ price.total }} lei</div>
                    <label id="total_value" :class="{ 'starting-with': allRequired == false }">
                        {{ price.total_promo > 0 ? price.total_promo : price.total }} lei
                    </label>
                    <div class="config_tva_text">({{ price.total_promo > 0 ? priceInEuro(price.total_promo) : priceInEuro(price.total) }}€) pretul include TVA</div>
                    <div class="config_warning_text mt-0 mb-0 mt-lg-3 mb-lg-3">
                        <p v-if="allRequired == false">
                            Selectează primii {{numberOfRequired}} pași pentru preț exact.
                        </p>
                    </div>
                </div>
                <div class="actions col-4 col-lg-12">
                    <input-button
                        :id="'prod-to-cart'"
                        :name="'add_one_item'"
                        v-on:click="addToCart()"
                    />
                </div>
                <brief-order-details v-show="allRequired" :slug="productSlug" :productname="productName" :price="price" class="col-2 col-lg-12" />
            </div>
            <AddToCartModal />
        </div>
    </div>
</template>

<script>
import BriefOrderDetails from "@/components/product_page/custom_hoch/BriefOrderDetails.vue";
import InputButton from "@/components/buttons/InputButton.vue";
import AddToCartModal from "@/components/product_page/custom_hoch/AddToCartModal.vue";
import { useStore } from "vuex";
import { computed, provide, readonly, ref } from "vue";
import { useGtm } from "@gtm-support/vue-gtm";
import { shopMixin } from "@/mixins/shopMixin";

export default {
    components: {
        BriefOrderDetails,
        InputButton,
        AddToCartModal,
    },
    mixins: [shopMixin],
    setup() {
        const gtm = useGtm();
        const { state, commit, dispatch } = useStore();

        const selectedSteps = computed(() => {
            return state.configurator.selectedProducts;
        });

        const allRequired = computed(() => {
            return state.configurator.allRequired;
        });

        const numberOfRequired = computed(() => {
            var countRequired = 0;
            var steps = state.configurator.steps;
            if(steps) {
                steps.forEach((valueElement) => {
                    if(valueElement.data.required_for_price) {
                        countRequired++;
                    }
                });
            }
            return countRequired;
        });

        const productSlug = computed(() => {
            return state.shop.product.slug;
        });
        
        const productName = computed(() => {
            let name = state.shop.product.name + state.configurator.productSufix;
            return name;
        });
        const price = computed(() => {
            var selectedSteps = state.configurator.selectedProducts;
            var steps = state.configurator.steps;
            var total = 0;
            var total_promo = 0;
            if (steps) {
                steps.forEach((element) => {
                    var isSelected = selectedSteps.find(
                        ({ step }) => step == element.data.id
                    );
                    if (isSelected) {
                        if (Array.isArray(isSelected.value)) {
                            isSelected.value.forEach((valueElement) => {
                                if (valueElement.price > 0) {
                                    total = total + parseInt(valueElement.price);
                                    var product_price = valueElement.price_promo > 0 ? parseInt(valueElement.price_promo) : parseInt(valueElement.price); 
                                    total_promo = total_promo + product_price;
                                }
                            });
                        } else {
                            if (isSelected.value.price > 0) {
                                total = total + parseInt(isSelected.value.price);
                                var product_price = isSelected.value.price_promo > 0 ? parseInt(isSelected.value.price_promo) : parseInt(isSelected.value.price); 
                                total_promo = total_promo + product_price;
                            }
                        }
                    }
                });
            }

            if (allRequired.value) {
                var final_total = total_promo > 0 ? total_promo : total;
                gtm.trackEvent({
                    event: "Calculator",
                    category: "Calculator",
                    label: productName.value + " : " + final_total + " lei",
                });
                total_promo = total_promo == total ? 0 : total_promo;

                return {'total': total, 'total_promo': total_promo};
            } else {
                return {'total': state.shop.product.offers[0].price > 0 ? state.shop.product.offers[0].price : state.shop.product.offers[0].old_price, 'total_promo': "0,00"};
            }
        });

        const scrollToTop = function () {
            var anchor = document.querySelector("#app");
            anchor.scrollIntoView({
                behavior: "smooth",
            });
        };

        const modal = ref(false);
        const openModal = function (value) {
            if (value == false) {
                dispatch("configurator/resetSteps");
                scrollToTop();
            }
            modal.value = value;
        };

        provide("price", readonly(price));
        provide("modal", readonly(modal));
        provide("productName", productName);
        provide("openModal", openModal);

        const addToCart = function () {
            if(!allRequired.value) {
                commit('configurator/setAddToCartError', true);
                let allErrors = document.getElementsByClassName('group_items error');
                let allUnselected = document.getElementsByClassName('group_items to-select');
                var anchor = allErrors[0] !== undefined ? allErrors[0] : allUnselected[0];

                var headerOffset = 70;
                var elementPosition = anchor?.getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });

            } else {
                let product = state.shop.product;
                dispatch("shop/addToCart", {
                    offer_id: product.offers[0].id,
                    quantity: 1,
                    property: {
                        "custom-name": productName.value,
                        "custom-price": price.value.total_promo > 0 ? price.value.total_promo : price.value.total,
                        "custom-options": JSON.stringify(selectedSteps.value),
                    },
                }).then(() => {
                    openModal(true);
                });
            }
        };

        return {
            allRequired,
            selectedSteps,
            price,
            productSlug,
            productName,
            state,
            commit,
            addToCart,
            openModal,
            numberOfRequired,
        };
    },
};
</script>

<style lang="scss" scoped>
#bottom-wizard {
    padding: 15px 0px 10px 0px;
    position: sticky;
    left: 0;
    top: 50px;
}

.produs-pret,
.produs-pret-vechi {
    font-size: 24px;
    color: #1a1f1f;
    float: left;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1;
    font-family: "Montserrat Bold";
}

#price_total {
    line-height: 1;
    float: left;
    margin-top: 5px;

    h3 {
        display: inline-block;
        margin: 0 5px 0 0;
        font-size: 28px;
        font-size: 1.4rem;
        padding: 0;
        font-weight: normal;
        color: #ccc;
        font-weight: bold;
        font-family: "Raleway Regular";
        font-weight: 500;
        color: #121111;
    }

    label {
        font-size: 28px;
        color: #97040c;
    }

    .old_value {
        font-family: 'Montserrat Regular';
        font-size: 15px;
        color: #535353;
        margin-bottom: 5px;
    }
}

.config_tva_text {
    font-family: Raleway Regular;
    font-size: 14px;
    font-weight: 500;
    color: #8d8d8d;
}

.starting-with {
    color: #000 !important;
}

.price-conf-wrap.no-price {
    .config_tva_text {
        display: none;
    }

    .actions {
        border-bottom: none;
    }

    .actions #prod-to-cart {
        background-color: #9d9d9d;
        cursor: unset;
    }
}

.config_warning_text p {
    font-family: "Montserrat SemiBold";
    font-size: 14px;
    line-height: normal;
    color: #a30800;
    text-align: left;
    margin-bottom: 0;
}

.actions {
    width: auto;
    float: left;

    .add-cos {
        height: auto;
        font-size: 14px;
        border-radius: 20px !important;
        padding: 9px 40px;
    }
}

@media (min-width: 992px) {
    #bottom-wizard {
        margin: 0 -15px;
    }

    .actions {
        padding-bottom: 20px;
        border-bottom: 1px solid #cbcbcb;
    }
}

@media (max-width: 991px) {
    #bottom-wizard {
        padding: 15px 0px 10px 0px;
        position: fixed;
        width: 100%;
        background-color: #ffffff;
        left: 0;
        bottom: 0px;
        top: unset;
        z-index: 9999;
    }

    #price_total {
        position: relative;
        width: 100%;
        margin-top: 0;
    }

    .price-conf-wrap.no-price #total_value {
        vertical-align: text-bottom;
        padding-left: 5px;
        margin-bottom: 0 !important;
    }

    .price-conf-wrap {
        display: flex;
        align-items: center;
        margin: 0;
        flex-wrap: nowrap;
    }

    .price-conf-wrap.no-price {
        justify-content: space-between;
    }
    
    .price-conf-wrap.no-price .starting-with {
        display: inline-block;
    }

    #configurator-wrap .panel {
        padding: 0;
    }

    a#details_bt {
        display: none !important;
    }

    .actions {
        order: 2;
    }
}

@media (max-width: 767px) {
    #price_total h3 {
        font-size: 1.125rem;
        font-family: Raleway Regular;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        display: block;
    }

    #price_total label {
        font-size: 25px;
    }

}

@media (max-width: 576px) {
    #bottom-wizard {
        padding: 5px 0 5px 0;
    }

    #price_total {
        margin: 0;
    }

    #price_total label {
        font-size: 22px;
        margin-bottom: 0;
    }
    
    .price-conf-wrap.no-price #price_total label {
        font-size: 18px;
    }

    .config_tva_text {
        font-size: 12px;
    }

    #price_total .old_value {
        font-size: 12px;
    }

    .config_warning_text p {
        font-size: 10px;
        line-height: 1;
    }

    .price-conf-wrap.no-price .config_warning_text p {
        font-size: 14px;
        width: 210px;
        margin: 5px 0;
    }
    
    #details_bt {
        top: 5px;
        right: 5px;
    }

    .comanda.add-cos {
        padding: 9px 15px;
        font-size: 12px;
    }
}
</style>
