<template>
    <div class="door-type row">
        <div class="col-12">
            <label class="title">3. Tip montaj</label>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-6 col-md-4">                   
                    <custom-door-radio-card 
                        :name="'options[door-type]'"
                        :value="'inner'"
                        :id="'type-in'"
                        :data-name="'Interior'" 
                    >
                        <label for="type-in" class="calc-options-label">Interior</label>
                    </custom-door-radio-card>
                </div>
                <div class="col-6 col-md-4">
                    <custom-door-radio-card
                        :name="'options[door-type]'"
                        :value="'outer'"
                        :id="'type-out'"
                        :data-name="'Exterior'"  
                    >
                        <label for="type-out" class="calc-options-label">Exterior</label>
                    </custom-door-radio-card>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import CustomDoorRadioCard from '@/components/product_page/custom_door/CustomDoorRadioCard.vue';
export default {
    components: {
        CustomDoorRadioCard
    },
}
</script>