<template>
  <div class="form-group">
    <vue-select
      close-on-select
      label-by="name"
      v-model="modelValue"
      :options="options"
      :min="1"
      placeholder="Selecteaza"
      openDirection="bottom"
      @update:modelValue="
        (value) => SetStep({ step: this.stepData.data.id, selected: value })
      "
    ></vue-select>
  </div>
</template>

<script>
import VueSelect from "vue-next-select";
import { useStore } from "vuex";
import { defineComponent, ref, computed } from "vue";

export default defineComponent({
  components: {
    "vue-select": VueSelect,
  },
  props: ["stepData"],
  setup(props) {
    const { state, commit } = useStore();
    var modelValue = ref([]);
    const loading = computed(() => {
      return state.configurator.loading;
    });
    const options = computed(() => {
      var stepsToPush = [];
      props.stepData.products.forEach((element) => {
        if (element.id != modelValue.value.id) {
          stepsToPush.push(element);
        } else {
          stepsToPush.push(modelValue.value);
        }
      });
      return stepsToPush;
    });

    return {
      modelValue,
      loading,
      state,
      commit,
      options,
    };
  },
  computed: {},
  created() {
    let steps = this.$store.getters["configurator/selectedProducts"];
    var stepID = this.stepID;
    var selected = "none";
    if (steps) {
      steps.forEach((element) => {
        if (element.step == stepID) {
          selected = "selected";
        }
        if (element.step == stepID - 1) {
          console.log("toselect - ", stepID - 1);
          selected = "to-select";
        }
      });
    }
    return selected;
  },
  methods: {
    SetStep(value) {
      this.$store.dispatch("configurator/selectStep", {
        step: value.step,
        value: value.selected,
      });
    },
  },
});
</script>

<style lang="scss">
// @import "vue-select/src/scss/vue-select.scss";

.add_bottom_30 {
  margin-bottom: 30px;
}

.style-chooser {
  max-width: 170px;
  position: relative;

  .vs__dropdown-toggle {
    position: relative;
    vertical-align: middle;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    padding: 0px;
    border: solid 2px #d9d9d9;

    .vs__selected-options {
      height: 42px;

      .vs__selected {
        margin: 0;
      }
    }

    .vs__actions {
      padding: 4px 6px 4px 6px;
    }
  }

  .vs__dropdown-menu::-webkit-scrollbar {
    width: 6px; /* width of the entire scrollbar */
  }

  .vs__dropdown-menu::-webkit-scrollbar-track {
    background: white; /* color of the tracking area */
  }

  .vs__dropdown-menu::-webkit-scrollbar-thumb {
    // background-color: grey;    /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    // border: 1px solid white;  /* creates padding around scroll thumb */
    background-color: rgb(191, 192, 193);
    border: 1px solid rgb(255, 255, 255);
  }

  .vs__dropdown-menu {
    top: calc(100% + 3px);
    font-size: 16px;
    color: #000;
    max-height: 245px;
    margin: 0;
    padding: 0;
    touch-action: auto !important;

    .vs__dropdown-option {
      padding: 16px 30px 18px;
      margin: 0;
      border-bottom: 1px solid #e5e5e5;
      line-height: 14px;
      font-family: Montserrat Medium;
      font-size: 14px;
      color: #5b5b5b;

      &:hover {
        background-color: #eeeeee !important;
        color: #000;
        background-image: none;
      }
    }
  }
}

.style-chooser.vs--open {
  .vs__dropdown-toggle {
    border-width: 3px 3px 3px;
    border-color: #9c1111 #9c1111 #9c1111;
  }
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #ffffff;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon.delete {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border: 0;
  background: 0 0;
  height: 8px;
  width: 8px;
  min-height: 8px;
  min-width: 8px;
  max-height: 8px;
  max-width: 8px;
  cursor: pointer;
}

.icon.arrow-downward {
  color: #999;
  content: url("~@/assets/images/configurator/arrow-select.svg");
  transition: transform 0.2s linear;
  cursor: pointer;
  border: none;
}

.icon.arrow-downward.active {
  transform: rotate(180deg);
}

#configurator-wrap .vue-select {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  outline: 0;
  width: 170px;
  max-width: 170px;
  border: none;
}

#configurator-wrap .vue-select[data-is-focusing="true"]:not([data-visible-length="0"]) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#configurator-wrap .vue-select[data-is-focusing="true"] .vue-select-header {
  outline: 3px solid #9c1111;
  outline-offset: -2px;
}

#configurator-wrap .vue-select.disabled {
  background-color: rgba(239, 239, 239);
}

#configurator-wrap .vue-dropdown[data-removable="false"] .vue-dropdown-item.selected:hover,
#configurator-wrap .vue-select.disabled *,
#configurator-wrap .vue-select.disabled input,
#configurator-wrap .vue-tags[data-removable="false"] .vue-tag.selected img:hover {
  cursor: not-allowed;
}

#configurator-wrap .vue-select-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
  vertical-align: middle;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  padding: 0px;
  border: solid 2px #d9d9d9;
  background: #fff;
  border-radius: 23px;
}

#configurator-wrap .vue-select-header > .icon.arrow-downward,
#configurator-wrap .vue-select-header > .icon.loading,
#configurator-wrap .vue-tag > span {
  margin-right: 15px;
}

#configurator-wrap .vue-tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 2px;
  min-height: calc(1rem + 4px);
  user-select: none;
}

#configurator-wrap .vue-tags.collapsed {
  flex-wrap: nowrap;
  overflow: auto;
}

#configurator-wrap .vue-tag {
  display: none;
  align-items: center;
  list-style-type: none;
  border-radius: 4px;
  margin: 2px;
  min-height: 1rem;
}

#configurator-wrap .vue-tag,
#configurator-wrap .vue-tag.selected {
  justify-content: center;
  background-color: #999;
  padding: 0 4px;
  font-size: 0.8rem;
}

#configurator-wrap .vue-dropdown,
#configurator-wrap .vue-input {
  min-width: 0;
  box-sizing: border-box;
}

#configurator-wrap .vue-input,
#configurator-wrap .vue-tag.selected {
  display: flex;
  align-items: center;
  border-radius: 4px;
}

#configurator-wrap .vue-input {
  max-width: 100%;
  padding: 4px;
  height: 42px;
}

#configurator-wrap .vue-select[data-is-focusing="false"] .vue-input > input,
input[readonly] {
  cursor: default;
}

#configurator-wrap .error .vue-select-header {
  border: solid 1px #97040c;
  background-color: #f8dcdc;
}

#configurator-wrap .vue-input,
#configurator-wrap .vue-input > input {
  border: 0;
  outline: 0;
  width: 100%;
}

#configurator-wrap .vue-input > input {
  min-width: 0;
  font-size: 0.9rem;
  padding: 0;
  padding-left: 10px;
  font-family: "Montserrat Medium";
}

#configurator-wrap .vue-input > input[disabled] {
  background-color: rgba(239, 239, 239);
}

#configurator-wrap .vue-input > input[readonly],
#configurator-wrap .vue-select-header > .vue-input > input[disabled] {
  background-color: unset;
}

#configurator-wrap .vue-dropdown {
  display: none;
  position: absolute;
  background-color: #fff;
  left: -1px;
  z-index: 1;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  top: calc(100% + 3px) !important;
  font-size: 16px;
  color: #000;
  max-height: 245px;
  // touch-action: auto !important;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px rgba(156, 156, 156, 0.5);
  clip-path: inset(-4px -10px -10px -10px);
}

#configurator-wrap .vue-select[aria-expanded="true"] .vue-dropdown {
  display: unset;
}

#configurator-wrap .vue-dropdown[data-visible-length="0"] {
  border: none;
}

#configurator-wrap .vue-dropdown::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

#configurator-wrap .vue-dropdown::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}

#configurator-wrap .vue-dropdown::-webkit-scrollbar-thumb {
  // background-color: grey;    /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  // border: 1px solid white;  /* creates padding around scroll thumb */
  background-color: rgb(191, 192, 193);
  border: 1px solid rgb(255, 255, 255);
}


#configurator-wrap .vue-dropdown-item {
  list-style-type: none;
  padding: 4px;
  cursor: pointer;
  min-height: 1rem;
  padding: 16px 30px 18px;
  margin: 0;
  border-bottom: 1px solid #e5e5e5;
  line-height: 14px;
  font-family: Montserrat Medium;
  font-size: 14px;
  color: #5b5b5b;
}

#configurator-wrap .vue-dropdown-item {
  &:hover {
    background-color: #eeeeee !important;
    color: #000;
    background-image: none;
  }
}

#configurator-wrap .vue-dropdown-item.disabled {
  background-color: rgba(239, 239, 239);
  cursor: not-allowed;
}

#configurator-wrap .vue-dropdown-item.selected {
  background-color: #f3f3f3;
}

#configurator-wrap .vue-dropdown-item.highlighted {
	background-color: #f3f3f3
}

#configurator-wrap .vue-dropdown-item.selected.highlighted {
	background-color: #f3f3f3
}

#configurator-wrap .vue-dropdown[data-addable="false"][data-multiple="true"]
  .vue-dropdown-item:not(.selected):hover {
  cursor: not-allowed;
}

.icon.loading {
  display: inline-block;
  position: relative;
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
}

.icon.loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 1px solid #999;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: loading 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #999 transparent transparent;
}

.icon.loading div:nth-child(1) {
  animation-delay: -0.08s;
}

.icon.loading div:nth-child(2) {
  animation-delay: -0.16s;
}
</style>
