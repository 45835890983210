<template>
    <div class="group_items ajax_sends" :class="[stepType, reqForPrice ? 'required-for-price' : '', (addToCartError && stepType != 'selected') ? 'error' : '']" data-id="11">
        <h4 :class="setAccordion" @click="openAccordion">{{ title }}</h4>
        <slot></slot>
        <div class="select-error" v-if="setAccordion == 'accordion' && addToCartError && stepType != 'selected'">{{currentStep.data.type == "text" ? "Completeaza" : "Selecteaza"}}</div>
    </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { useGtm } from "@gtm-support/vue-gtm";

export default {
    props: ["title", "toselect", "disabled", "accordion", "selected", "stepID"],

    setup(props) {
        const event = useGtm();
        const { state, commit } = useStore();
        const setAccordion = computed(() => {
            var allSteps = state.configurator.steps;
            var stepID = props.stepID;
            var selected = "accordion";

            if (allSteps) {
                var isSelected = allSteps.find(({ data }) => data.id == stepID);
                if (isSelected) {
                    if (isSelected.data.required) {
                        selected = "without-accordion";
                    }
                }
            }
            return selected;
        });

        const productName = computed(() => {
            let name = state.shop.product.name + state.configurator.productSufix;
            return name;
        });

        const stepType = computed(() => {
            let firtStepsID = state.configurator.steps[0].data.id;
            let steps = state.configurator.selectedProducts;
            var allRequired = state.configurator.allRequired;
            var stepID = props.stepID;
            var selected = "none";

            var currentStep = steps.find(({ step }) => step == stepID);
            var nextStep = steps.find(({ step }) => step == parseInt(stepID) - 1);
            if (firtStepsID == stepID && currentStep == undefined) {
                selected = "to-select";
            } else if (currentStep) {
                selected = "selected";
            } else if (nextStep && allRequired == false) {
                selected = "to-select";
            } else if (allRequired == false) {
                selected = selected + " disabled";
            } else {
                // selected = selected + ' disabled';
            }
            if (firtStepsID + 1 == stepID && selected == "to-select") {
                event.trackEvent({
                    event: "start configurator",
                    category: "Calculator",
                    label: "start: " + productName.value,
                });
            }

            return selected;
        });

        const currentStep = computed(() => {
            var allSteps = state.configurator.steps;
            var stepID = props.stepID;
            if (allSteps) {
                var isSelected = allSteps.find(({ data }) => data.id == stepID);
                return isSelected;
            } else {
                return false;
            }
        });

        const addToCartError = computed(() => {
            if (currentStep?.value?.data?.required_for_price && state.configurator.addToCartError) {
                return true;
            } else {
                return false;
            }
        });

        const reqForPrice = computed(() => {
            if(currentStep?.value?.data?.required_for_price) {
                return true;
            } else {
                return false;
            }
        });

        return {
            setAccordion,
            stepType,
            state,
            commit,
            addToCartError,
            reqForPrice,
            currentStep,
        };
    },
    computed: {},
    provide: {
        modal: false,
    },
    methods: {
        openAccordion(event) {
            let title = event.target;
            if (this.hasClass(title.classList)) {
                let panel = title.nextElementSibling;
                let error = panel?.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                    if(error) {
                        error.style.display = 'block';
                    }
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                    if(error) {
                        error.style.display = 'none';
                    }
                }
            }
        },
        hasClass(arr) {
            let hClass = false;
            arr.forEach((element) => {
                if (element === "accordion") {
                    hClass = true;
                }
            });
            return hClass;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 20px;

.group_items {
    border-bottom: 1px solid #cbcbcb;
    margin-bottom: 0;
    position: relative;

    h4 {
        font-family: "Raleway Bold";
        // font-size: 18px;
        line-height: 26px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: 18px 18px 25px 0;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }

    h4.without-accordion {
        color: #121111;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        transition: 0.4s;
        margin-top: 0;
    }

    h4.accordion {
        color: #121111;
        cursor: pointer;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        // font-size: 15px;
        transition: 0.4s;
        margin-top: 0;

        &:before {
            content: url("~@/assets/images/configurator/arrow_down_acc_configurator.png");
            color: #777;
            font-weight: bold;
            margin-left: 5px;
            order: 3;
            margin-left: auto;
        }
    }

    h4.accordion.active {
        &:before {
            content: url("~@/assets/images/configurator/arrow_up_acc_configurator.png");
        }
    }
}

.group_items.selected {
    h4 {
        color: #000 !important;
        &:after {
            content: url("~@/assets/images/configurator/black-check.png");
            height: 26px;
            padding-left: 10px;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
        }
    }
}


.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.select-error {
    font-family: "Montserrat Medium";
    font-size: 14px;
    color: #97040c;
    position: absolute;
    bottom: 5px;
}
</style>
