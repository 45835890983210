<template>
  <div class="ribbon">
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.ribbon {
  span {
    font-size: 12px;
    font-size: 0.5rem;
    font-weight: bold;
    font-style: normal;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #8dc63f;
    -webkit-box-shadow: 0px 3px 10px -5px black;
    -moz-box-shadow: 0px 3px 10px -5px black;
    box-shadow: 0px 3px 10px -5px black;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 25%);
    position: absolute;
    top: 19px;
    right: -21px;

    &:before {
      content: "";
      position: absolute;
      left: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid #638908;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #638908;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid #638908;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #638908;
    }
  }

  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
</style>
