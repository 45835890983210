<template>
  <div class="configurator_inner price_calc" id="configurator-wrap">
    <div class="configurator-inner-div">
      <h2 class="mobile calc-pret-title">Calculeaza pret</h2>

      <div
        v-for="step in steps"
        :key="step.data.id + '-' + step.products.length"
        class="steps-wrp"
      >
        <custom-hoch-step
          :title="step.data.id - firstSteps + '. ' + step.data.name"
          :accordion="true"
          :selected="'none'"
          :stepID="step.data.id"
        >
          <custom-hoch-select
            v-if="step.data.type == 'select'"
            :key="step.data.id + '-' + step.products.length"
            :stepData="step"
          >
          </custom-hoch-select>
          <custom-hoch-option-card v-else-if="step.data.type == 'text'">
            <custom-hoch-input :stepData="step" />
          </custom-hoch-option-card>
          <custom-hoch-option-card v-else>
            <custom-hoch-option :stepData="step" />
          </custom-hoch-option-card>
        </custom-hoch-step>
      </div>
    </div>
  </div>
</template>

<script>
import CustomHochStep from "@/components/product_page/custom_hoch/CustomHochStep.vue";
import CustomHochSelect from "@/components/product_page/custom_hoch/CustomHochSelect.vue";
import CustomHochOption from "@/components/product_page/custom_hoch/CustomHochOption.vue";
import CustomHochOptionCard from "@/components/product_page/custom_hoch/CustomHochOptionCard.vue";
import CustomHochInput from "@/components/product_page/custom_hoch/CustomHochInput.vue";

import { useStore } from "vuex";
import { ref, computed } from "vue";

export default {
  components: {
    CustomHochStep,
    CustomHochSelect,
    CustomHochOption,
    CustomHochOptionCard,
    CustomHochInput,
  },

  setup() {
    const { state, commit } = useStore();
    const modelValue = ref([]);
    const firstSteps = computed(() => {
      return state.configurator.steps[0].data.id - 1;
    });
    const isLoading = computed(() => {
      // return true;
      return state.configurator.loading;
    });

    return {
      modelValue,
      state,
      commit,
      firstSteps,
      isLoading,
    };
  },
  data() {
    return {};
  },
  computed: {
    steps() {
      let steps = this.$store.getters["configurator/steps"];
      return steps;
    },
    selectedSteps() {
      return this.$store.getters["configurator/selectedProducts"];
    },
  },
  beforeCreate() {
    // this.$store.dispatch("configurator/getProductSteps");
  },
  mounted() {
    this.$store.dispatch("configurator/resetSteps");
    this.$store.commit('configurator/setAddToCartError', false);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
#configurator-wrap {
  padding-top: 20px;
  padding-right: 20px;
  border-right: 1px solid #cbcbcb;
}

.calc-pret-title {
  font-family: Raleway Regular;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #121111;
}

@media (max-width: 991px) {
  #configurator-wrap {
    border-right: 0;
  }
}

@media (max-width: 768px) {
  #configurator-wrap {
    padding-top: 30px;
    padding-right: 0px;
  }
}
</style>
