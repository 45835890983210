<template>
  <section v-if="product.name" class="container p-3 rounded product-page-detailed pb-4 custom-glass">
    <brief-info :name="product.name" :code="product.code" :text="product.preview_text" />

    <div class="calculator-sticla-wrapper calc-usa calculator-pret" id="calc-usa">
      <div class="row">
        <div class="calculator-sticla col-12 col-md-7">
          <custom-glass-dimensions />
          <div class="row">
            <div class="col-lg-6 co-12 pt-2 pb-2">
              <total-price-calculator
                :is-complete="allIsComplete"
                :width="glassWidthDim"
                :height="glassHeightDim"
              />
            </div>
            <div class="col-lg-6 col-12 pt-2 pb-2">
              <div class="relative order-inputs">
                <input-button
                  :disabled="!allIsComplete"
                  :id="'prod-to-cart'"
                  :name="'add_one_item'"
                  v-on:click="addToCart()"
                />
              </div>
            </div>
          </div>
        </div>

        <AddToCartModal :quantity="1" ref="addtocart" />
        <div class="col-12 col-md-5 d-md-block d-none draw-image">
          <draw-glass v-if="allIsComplete" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 product-image">
        <carousel :images="product.images" :type="'custom'" />
      </div>
      <div class="col-lg-6 product-info">
        <!-- <specifications /> -->
        <instead-of-specification :description="getCustomDescription" />
      </div>

      <div class="col-12 wysiwyg mt-3 product-content">
        <more-info :description="product.description" />
      </div>

      <div class="col-12 product-info-points">
        <info-points :slug="product.slug" :id="product.id" />
      </div>

      <div class="col-lg-6 product-options">
        <product-options :accesories="product.accesories" />
      </div>
    </div>
  </section>
</template>

<script>
import CustomGlassDimensions from "@/components/product_page/custom_glass/CustomGlassDimensions.vue";
import TotalPriceCalculator from "@/components/product_page/custom_glass/TotalPriceCalculator.vue";
import BriefInfo from "@/components/product_page/BriefInfo.vue";
import DrawGlass from "@/components/product_page/custom_glass/DrawGlass.vue";
import Carousel from "@/components/product_page/Carousel.vue";
import InsteadOfSpecification from "@/components/product_page/InsteadOfSpecification.vue";
import MoreInfo from "@/components/product_page/MoreInfo.vue";
import ProductOptions from "@/components/product_page/ProductOptions.vue";
import InfoPoints from "@/components/product_page/InfoPoints.vue";
import InputButton from "@/components/buttons/InputButton.vue";
import AddToCartModal from "@/components/product_page/AddToCartModal.vue";

export default {
  components: {
    CustomGlassDimensions,
    TotalPriceCalculator,
    BriefInfo,
    DrawGlass,
    Carousel,
    InsteadOfSpecification,
    MoreInfo,
    InfoPoints,
    InputButton,
    ProductOptions,
    AddToCartModal,
  },

  computed: {
    product() {
      return this.$store.getters["shop/product"];
    },

    allIsComplete() {
      return this.glassHeightDim > 0 && this.glassWidthDim > 0;
    },

    glassWidthDim() {
      return this.$store.getters["glass/glassWidthDim"];
    },

    glassHeightDim() {
      return this.$store.getters["glass/glassHeightDim"];
    },

    glassPrice() {
      return this.$store.getters["glass/glassPrice"];
    },
    productName() {
      var name = this.product.name;
      if (this.glassWidthDim > 0) {
        name += " - " + this.glassWidthDim + " mm";
      }
      if (this.glassHeightDim > 0) {
        name += " x " + this.glassHeightDim + " mm";
      }
      return name;
    },
    customQuantity() {
      var quantity = 1;
      if (this.glassWidthDim > 0 && this.glassHeightDim > 0) {
        var dimension = (this.glassHeightDim * this.glassWidthDim) / 1000000;
        var precision = Math.pow(10, 2);
        quantity = Math.ceil(dimension * precision) / precision;
      }
      return quantity;
    },
    getCustomDescription() {
      let product = this.product;
      let description = {};
      if (product.layout_property) {
        description = product.layout_property.find((item) => {
          return item.code === "product-short-description";
        });
      }
      return description ? description.value : "";
    },
  },
  methods: {
    addToCart() {
      // console.log("adaugam in cos sticla");
      this.$store
        .dispatch("shop/addToCart", {
          offer_id: this.product.offers[0].id,
          quantity: 1,
          property: {
            "custom-name": this.productName,
            "custom-price": this.glassPrice,
            "custom-quantity": this.customQuantity,
            "custom-options": JSON.stringify({
              latime: this.glassWidthDim,
              inaltime: this.glassHeightDim,
            }),
          },
        })
        .then(() => {
          this.$store.dispatch("modals/addToCartModal", true);
          this.$store.dispatch("glass/actionGlassHeightDim", 0);
          this.$store.dispatch("glass/actionGlassWidthDim", 0);
          this.$store.dispatch("glass/setPrice", 0);
        });
    },
  },
};
</script>

<style lang="scss">
.calculator-sticla-wrapper {
  width: 100%;
  border: 1px solid #999;
  padding: 15px;
  position: relative;
  padding-top: 0px;
  margin-bottom: 20px;

  div.relative {
    width: 190px;
    text-align: right;
  }
}

// buton add

.calculator-sticla-wrapper {
  .comanda.add-cos {
    height: 50px;
    width: 100%;
    left: auto;
    right: auto;
    margin: auto;
    font-size: 16px;
    background-image: url("~@/assets/images/cart.png");
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: 10px center;
  }
}

.custom-glass .product-detailed__carousel.carousel {
  top: 0 !important;
}

@media (min-width: 991px) {
  .custom-glass .thumb-gallery {
    bottom: 143px !important;
    left: -143px !important;
  }
}

@media (min-width: 1200px) {
  .custom-glass .thumb-gallery {
    bottom: 187px !important;
    left: -187px !important;
  }
}

@media (max-width: 768px) {
  .custom-glass .product-detailed__carousel.carousel {
    margin-left: 0 !important;
    margin-bottom: 82px !important;
    width: 100% !important;
  } 
}

</style>
