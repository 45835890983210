<template>
  <div class="dor-dimensions row">
    <div class="col-12">
      <label class="title">1. Calculează preț ușă personalizată</label>
    </div>
    <div class="input-calculator col-12">
      <div class="row">
        <div class="col-12 col-sm-6">
          <dimension
            @dimValue="setHeight"
            :glass="dimHeight"
            :min="minHeight"
            :max="maxHeight"
            :dim="doorHeightDim"
          />
        </div>
        <div class="col-12 col-sm-6">
          <dimension
            @dimValue="setWidth"
            :glass="dimWidth"
            :min="minWidth"
            :max="maxWidth"
            :dim="doorWidthDim"
          />
        </div>
      </div>
    </div>
    <div class="explicatie col-12">
      <span>Dimensiunile se dau în mm! 1cm = 10mm</span>
    </div>
  </div>
</template>

<script>
import Dimension from "@/components/product_page/custom_glass/Dimension.vue";

export default {
  components: {
    Dimension,
  },
  props: ["images"],
  computed: {
    dimHeight() {
      return {
        title: "Înălțime",
        class: "inaltime",
      };
    },
    dimWidth() {
      return {
        title: "Lățime",
        class: "lungime",
      };
    },
    minHeight() {
      let product = this.$store.getters["shop/product"];
      let minHeight = {};
      if (product.layout_property) {
        minHeight = product.layout_property.find((item) => {
          return item.code === "minim-h";
        });
      }
      return parseInt(minHeight.value ? minHeight.value : 50);
    },
    maxHeight() {
      let product = this.$store.getters["shop/product"];
      let maxHeight = {};
      if (product.layout_property) {
        maxHeight = product.layout_property.find((item) => {
          return item.code === "maxim-h";
        });
      }
      return parseInt(maxHeight.value ? maxHeight.value : 2400);
    },
    minWidth() {
      let product = this.$store.getters["shop/product"];
      let minWidth = {};
      if (product.layout_property) {
        minWidth = product.layout_property.find((item) => {
          return item.code === "minim-l";
        });
      }
      return parseInt(minWidth.value ? minWidth.value : 50);
    },
    maxWidth() {
      let product = this.$store.getters["shop/product"];
      let maxWidth = {};
      if (product.layout_property) {
        maxWidth = product.layout_property.find((item) => {
          return item.code === "maxim-l";
        });
      }
      return parseInt(maxWidth.value ? maxWidth.value : 2400);
    },
    doorWidthDim() {
      return this.$store.getters["door/doorWidthDim"];
    },

    doorHeightDim() {
      return this.$store.getters["door/doorHeightDim"];
    },
  },
  methods: {
    setHeight(value) {
      const intPictureText = document.getElementsByClassName("draw-inaltime");
      Array.from(intPictureText).forEach((element) => {
        if (parseInt(value) > 0) {
          element.textContent = value + " mm";
        } else {
          element.textContent = "Înălțime";
        }
      });
      this.$store.dispatch("door/actionDoorHeightDim", value);
    },

    setWidth(value) {
      const intPictureText = document.getElementsByClassName("draw-lungime");
      Array.from(intPictureText).forEach((element) => {
        if (parseInt(value) > 0) {
          element.textContent = value + " mm";
        } else {
          element.textContent = "Lățime";
        }
      });
      this.$store.dispatch("door/actionDoorWidthDim", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.calculator-sticla {
  .cell {
    width: 85px;
    color: #3c3f48;
    font-size: 18px;
    line-height: 48px;
    height: 50px;
    position: relative;

    &:first-child {
      background-color: #3c3f48;
      color: #fff;
      font-size: 14px;
      font-family: "Raleway";
      text-align: center;
      width: 105px;
    }

    input {
      width: 100%;
      text-align: left;
      padding-left: 10px;
      font-size: 14px;
      height: 100%;
      border: 1px solid #3c3f48;
      font-family: "Montserrat Regular";

      &:focus {
        outline: 0;
      }
    }

    span {
      position: absolute;
      top: 2px;
      right: 5px;
      font-family: "Montserrat Regular";
      font-size: 14px;
    }
  }
}

.maxi,
.maxl {
  position: absolute;
  top: 0;
  background: #9d0000;
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  display: none;
}

.explicatie {
  color: #ff0000;
  /* font-weight: bold; */
  font-family: "Montserrat Regular";
  width: 100%;
  height: auto;
  line-height: 50px;
}

@media (max-width: 576px) {
  .inaltime {
    margin-bottom: 10px;
  }

  .explicatie {
    line-height: normal;
    margin-top: 10px;
  }
}
</style>
