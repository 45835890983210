<template>
    <div v-if="categIsLoad && loading" class="default-bg without-banner margin-top">
        <page-header :overlay="false" />
        <!-- <slogan-livrare :slogan="slogan" :shipping="shipping" /> -->
        <breadcrumb :breadcrumbs="product.category.breadcrumbs" />

    <component :is="typeProduct" :key="product.slug"></component>
    <AddToCartScroll v-if="product.active" :offer="product.offers[0]" />
  </div>
  <SkeletonProduct v-else />
</template>

<script>
import PageHeader from "@/components/page_header/PageHeader.vue";
// import SloganLivrare from "@/components/product_list/SloganLivrare.vue";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue";
import SkeletonProduct from "@/components/blocks/skeleton/shop/SkeletonProduct.vue";
import ProductContainer from "@/components/product_page/ProductContainer.vue";
import Outlet from "@/components/product_page/Outlet.vue";
import CustomDoor from "@/components/product_page/CustomDoor.vue";
import CustomGlass from "../../components/product_page/custom_glass/CustomGlass.vue";
import CustomHoch from "../../components/product_page/custom_hoch/CustomHoch.vue";
import FullWidthContent from "../../components/product_page/fullwidthcontent/FullWidthContent.vue";

import { generalMixin } from "@/mixins/generalMixin";
import AddToCartScroll from "@/components/product_page/AddToCartScroll.vue";

export default {
    name: "ProductPage",
    components: {
        "page-header": PageHeader,
        // "slogan-livrare": SloganLivrare,
        Breadcrumb,
        SkeletonProduct,
        "product-container": ProductContainer,
        Outlet,
        CustomDoor,
        CustomGlass,
        CustomHoch,
        FullWidthContent,
        AddToCartScroll,
    },
    data() {
        return {
            product: null,
            loading: false,
        };
    },

    computed: {
        typeProduct() {
            let layout = {};
            if (this.product.layout_property) {
                layout = this.product.layout_property.find((item) => {
                    return item.code === "layout";
                });
            }
            switch (layout?.value) {
                case "custom-hoch":
                    return CustomHoch;
                case "custom-door":
                    return CustomDoor;
                case "custom-glass":
                    return CustomGlass;
                case "full-width-content":
                    return FullWidthContent;
                case "outlet":
                    return Outlet;
                default:
                    return ProductContainer;
            }

            // return CustomHoch;
        },
        header_image() {
            return { path: this.product.category.header_image };
        },
        categ() {
            // console.log(this.$store.getters["shop/category"]);
            return this.$store.getters["shop/category"];
        },
        categIsLoad() {
            return this.$store.getters["shop/categoryIsLoad"];
        },
        slogan() {
            let message = "";
            if (this.categIsLoad && this.product.category.transport_caption) {
                message = this.product.category.transport_caption;
            }
            if (this.categIsLoad && this.product.category.transport_caption === "0") {
                message = "Livrarea in toata Romania";
            }
            return message;
        },
        shipping() {
            let transport = false;
            if (this.categIsLoad && this.product) {
                transport = this.product.labels.find((elem) => {
                    return elem.code === "transport-gratuit";
                });
            }

            if (transport) {
                transport = true;
            }

            return transport;
        },
    },
    mixins: [generalMixin],

    watch: {
        '$route'() {
            this.loadData();
        },
    },
    async created() {
        this.loadData();
    },
    methods: {
        async loadData() {
            const url_segments = this.$route.path.split("/");
            const product_slug = url_segments[url_segments.length - 1];

            this.$store
            .dispatch("shop/getProduct", product_slug)
            .then((response) => {
                if (response?.data) {
                    this.product = this.$store.getters["shop/product"];
                    this.loading = true;
                }
            })
            .then(() => {
                this.$store.dispatch("shop/actCategoryIsLoad", true);
            });
        },
    },

    unmounted() {
        this.$store.dispatch("shop/resetProductObj");
        this.$store.dispatch("modals/addToCartModal", false);
    },
};
</script>

<style lang="scss" scoped>
.default-bg {
    background: #f5f4f2;
}
</style>
