<template>
<div id="configurator-included-products">
  <a class="btn-view-details" @click="openAdditionalProducts = true">
    <span v-if="showStepsText">Vezi produse incluse</span> <img src="@/assets/images/watch-eye.svg" />
  </a>
  <VueFinalModal
    v-model="openAdditionalProducts"
    :content-transition="'vfm-slide-right'"
    class="configurator-products"
    content-class="configurator-products-content"
  >
    <div class="configurator-products-header">
      <a class="share-list mr-4" @click="copyList" :style="copySuccess ? 'color:#62ba46' : 'color:#000'">
        <img v-show="copySuccess" src="@/assets/images/configurator/check-select.svg" /> 
        <img v-show="!copySuccess" src="@/assets/images/copy-list.svg" /> 
        Copiaza lista
      </a>
      <a class="share-list" @click="shareList">
        <img src="@/assets/images/share-list.svg" /> Distribuie lista
      </a>
      <button class="btn btn-close close-modal float-right" @click="openAdditionalProducts = false">
        <img src="@/assets/images/x-close.svg" />
      </button>
    </div>
    <h4 class="main-title">Produse incluse</h4>
    <div v-show="spinner" style="position: relative; height: 100%;"><loading style="top:30px; align-items:start;" :active="spinner" :is-full-page="false"> </loading></div>
    <!-- If configurator is not yet created, use it with computed data (steps prop is sent only for already created configurators) -->
    <div v-if="steps === undefined" class="configurator-products-body">
      <div v-for="product in includedProducts" :key="product.id" class="configurator-item row no-gutters">
        <div class="col-12 col-md-9">{{ product.quantity + ' x ' + product.name }}</div>
        <div class="col-12 col-md-3 price">{{ product.price * product.quantity }} lei</div>
      </div>
      <div v-show="additionaltext" class="row no-gutters configurator-details">
        <div class="col-12">
          {{ additionaltext }}
        </div>
      </div>
    </div>
    <!-- If configurator is already created -->
    <div v-else-if="createdIncludedProducts" class="configurator-products-body">
      <div v-for="product in createdIncludedProducts" :key="product.id" class="configurator-item row no-gutters">
        <div class="col-12 col-md-9">{{ product.quantity + ' x ' + product.name }}</div>
        <div class="col-12 col-md-3 price">{{ product.price * product.quantity }} lei</div>
      </div>
      <div v-show="additionaltext" class="row no-gutters configurator-details">
        <div class="col-12">
          {{ additionaltext }}
        </div>
      </div>
    </div>
    <div class="configurator-products-footer">
    </div>
  </VueFinalModal>
</div>
</template>

<script>
import { useRouter } from "vue-router";
import { defineComponent, toRaw } from "vue";
import { VueFinalModal } from 'vue-final-modal';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default defineComponent({
  components: {
    VueFinalModal,
    Loading,
  },
  data() {
    // Exceptie configurator DW Black & Eco :(
    const dwConfigurators = [23469, 23863];

    return {
      openAdditionalProducts: false,
      copySuccess: false,
      showStepsText: this.steps || window.innerWidth > 991 ? true : false,
      createdIncludedProducts: [],
      spinner: false,
      additionaltext: dwConfigurators.includes(parseInt(this.slug.split('_')[1])) ? '*Toate colierele de îmbinare pentru montaj rapid sunt incluse !' : false,
    };
  },
  props: ['price', 'steps', 'productname', 'slug'],
  computed: {
    includedProducts() {
      return this.$store.getters["configurator/includedProducts"];
    },
  },
  setup() {
    const router = useRouter();
    let navigationGuard = null;

    return {
      router,
      navigationGuard,
    };
  },
  watch: {
    openAdditionalProducts(val) {
      var mainElement = document.getElementById('bottom-wizard');
      if(val) {
        if(this.steps) {
          this.spinner = true;
          this.$store.dispatch("configurator/getConfiguratorAllProducts", JSON.parse(this.steps)).then(() => {
            this.createdIncludedProducts = this.$store.getters["configurator/includedProducts"];
            this.spinner = false;
          });
        } else {
          mainElement?.classList.add('z-index-99');
        }
        // Prevent navigating away if the modal is opened
        this.navigationGuard = this.router.beforeEach((to, from, next) => {
          if (val) {
            // Close the modal when navigating away
            this.openAdditionalProducts = false;
            next(false); // Prevent router navigation
          } else {
            next();
          }
        });
      } else {
        setTimeout(() => {
          if(mainElement?.classList.contains('z-index-99')) {
            mainElement.classList.remove('z-index-99');
          }
          this.copySuccess = false;
        }, 200);

        if (this.navigationGuard) {
          this.navigationGuard(); // Call the guard function to remove it
          this.navigationGuard = null; // Reset the guard variable
        }

      }

      
    },
  },
  methods: {
    stringIncludedProducts() {
      var product_name = this.productname;
      var includedProds = this.steps ? toRaw(this.createdIncludedProducts) : toRaw(this.includedProducts);
      var configuratorText = '*' + product_name + '*' + '\n';
      configuratorText += 'Produse incluse: \n';
      Object.keys(includedProds).forEach(key=>{
        configuratorText += includedProds[key].quantity + ' x ' + includedProds[key].name + ' = ' + (includedProds[key].price * includedProds[key].quantity) + ' lei \n';
      });
      configuratorText += this.additionaltext ? this.additionaltext + '\n' : '';
      if(this.price.total_promo > 0) {
        configuratorText += '*Total pret redus: ' + this.price.total_promo + ' lei*';
      } else {
        configuratorText += '*Total pret: ' + this.price.total + ' lei*';
      }
      return configuratorText;
    },
    async copyList() {
      let configuratorText = this.stringIncludedProducts();
      try {
        //navigator works only on HTTPS
        await navigator.clipboard.writeText(configuratorText);
        this.copySuccess = true;
      } catch($e) {
        // alert('Lista nu a putut fi copiata!');
      }
    },
    async shareList() {
      let configuratorText = this.stringIncludedProducts();
      try {
        //navigator works only on HTTPS
        await navigator.share({
          text: configuratorText,
        });
      } catch($e) {
        // alert('Browser-ul folosit nu este compatibil cu aceasta functie!');
      }
    }
  },

});
</script>

<style>
#configurator-included-products {
  width: 100%;
  padding: 15px 0;
}

.configurator-products .configurator-products-content {
  border-radius: 33px 0 0 33px;
  /* padding: 50px 30px 50px 50px; */
  background: #fff;
  width: 540px;
  height: 100%;
  float: right;
  position: relative;
  padding-left: 40px;
  padding-right: 25px;
}

.configurator-products .configurator-products-header {
  padding: 40px 0 60px 0;
}

.configurator-products .configurator-products-header .share-list {
  font-family: Montserrat SemiBold;
  font-size: 12px;
  cursor: pointer;
}
.configurator-products .configurator-products-header .share-list img {
  height: 16px;
  width: auto;
}

.configurator-products .main-title {
  font-family: Raleway ExtraBold;
  font-size: 24px;
  padding-bottom: 30px;
  border-bottom: solid 1.2px #cdcbcb;
}

.configurator-products-body {
  height: calc(100% - 260px);
  overflow-y: scroll;
}

.configurator-products-body .configurator-item {
  font-family: Raleway SemiBold;
  font-size: 16px;
  padding: 15px 0 15px 0;
  border-bottom: solid 1.2px #cdcbcb;
}

.configurator-products-body .configurator-item div {
  line-height: 1.2;
}

.configurator-products-body .configurator-item .price {
  font-family: Montserrat Bold;
}

.configurator-products-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 5px;
  background-color: #fff;
  border-radius: 33px;
}

.configurator-products-footer::before {
  content: "";
  position: absolute;
  background: linear-gradient(to top, rgba(255,255,255,0.8), rgba(255,255,255,0.1));
  width: 100%;
  height: 25px;
  left: 0;
  top: -24px;
}

#configurator-included-products .btn-view-details {
  font-family: Montserrat Bold;
  font-size: 14px;
  text-decoration: underline !important;
  cursor: pointer;
}

#configurator-included-products .btn-view-details img {
  height: 25px;
  float: right;
}

#configurator-included-products .configurator-details {
  margin-top: 10px;
  font-family: Montserrat Bold;
  font-size: 14px;
}

.z-index-99 {
  z-index: 99;
}

@media (min-width: 768px) {
  .configurator-products-body .configurator-item .price {
    text-align: right;
  }
}

@media (min-width: 991px) {
  #configurator-included-products {
    border-bottom: 1px solid #cbcbcb;
  }

  .configurator-products-body::-webkit-scrollbar {
    width: 12px;
  }

  .configurator-products-body::-webkit-scrollbar-track {
    background-color: #fff;
  }

  .configurator-products-body::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #d8d8d8;
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
}

@media (max-width: 991px) {
  #configurator-included-products {
    order: 1;
  }

  #configurator-included-products .btn-view-details img {
    height: 38px;
    padding-right: 10px;
  }

  #configurator-included-products.in-cart .btn-view-details img {
    height: 28px;
  }

}

@media (max-width: 767px) {

  #configurator-included-products .btn-view-details img {
    height: 36px;
  }

  #configurator-included-products.in-cart .btn-view-details img {
    height: 22px;
    margin-top: 2px;
  }
  
  .configurator-products-body {
    height: calc(100% - 210px);
  }

  .configurator-products .configurator-products-content {
    width: 100%;
    border-radius: 0;
    padding: 0 20px;
  }

  .configurator-products .configurator-products-header {
    padding: 20px 0 40px 0;
  }

  .configurator-products-body .configurator-item .price {
    margin-top: 10px;
  }
}

#configurator-included-products.in-cart {
  display: inline-block;
  border: none;
  padding: 0;
  width: auto;
  margin-top: 5px;
}
#configurator-included-products.in-cart .btn-view-details {
  padding: 0;
  margin: 0;
}
#configurator-included-products.in-cart .btn-view-details {
  font-size: 10px;
}
#configurator-included-products.in-cart .btn-view-details img {
  height: 20px;
  margin-left: 5px;
}

</style>