<template>
  <div class="sticla_wrp d-flex">
    <div
      id="sticla"
      class="draw_image"
      :style="{ width: pictureWidth + 'px', height: pictureHeight + 'px' }"
    >
      <div id="inaltime_sticla" class="text_is">{{ glassHeightDim }} mm</div>
      <div id="lungime_sticla" class="text_ls">{{ glassWidthDim }} mm</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    glassWidthDim() {
      return +this.$store.getters["glass/glassWidthDim"];
    },

    glassHeightDim() {
      return +this.$store.getters["glass/glassHeightDim"];
    },
    layoutDim() {
      // stabilim reperul dimensiuni maxime ale layout-ului
      const widhtInPx = document.querySelector(".draw-image")?.offsetWidth;
      const heightInPx = document.querySelector(".draw-image")?.offsetHeight;

      const reper = Math.min(widhtInPx, heightInPx) - 25;
      //   console.log(reper);
      const unit = reper / 100;
      return {
        reper: reper,
        unit: unit,
      };
    },
    userDim() {
      // stabilim reper dimensiuni introduse
      const reper_user = Math.max(this.glassWidthDim, this.glassHeightDim);
      //   console.log("repre user", reper_user);
      const unit_user_input = reper_user / 100;
      //   console.log("unit-user", unit_user_input);
      return {
        reper: reper_user,
        unit: unit_user_input,
      };
    },

    pictureWidth() {
      return this.glassWidthDim > this.glassHeightDim
        ? this.layoutDim.reper
        : Math.floor(this.glassWidthDim / this.userDim.unit) * this.layoutDim.unit;
    },
    pictureHeight() {
      return this.glassHeightDim > this.glassWidthDim
        ? this.layoutDim.reper
        : Math.floor(this.glassHeightDim / this.userDim.unit) * this.layoutDim.unit;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.sticla_wrp {
  margin-bottom: 25px;
}
#sticla {
  border: 2px solid #dcb753;
  position: relative;
  top: 25px;
  left: -2px;
  background-image: url(~@/assets/images/product/custom-glass/glass_bg.png);
  // max-width: 2000px;
  //   height: 100px;

  .text_is {
    color: #aaa;
    font-size: 11px;
    position: absolute;
    top: 15px;
    right: -35px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    width: auto;
    white-space: nowrap;
  }

  .text_ls {
    font-size: 11px;
    color: #aaa;
    position: absolute;
    top: -20px;
    left: 1px;
    width: auto;
    white-space: nowrap;
  }
}
</style>
