<template>
  <div class="row">
    <div class="col-lg-5">
      <brief-info
        :name="product.name"
        :text="product.preview_text"
        :hochclass="'custom-hoch-title'"
      />
      <carousel v-if="windowWidth > 768" :images="product.images" :product="product" />
      <product-carousel-mobile
        v-if="windowWidth < 769"
        :images="product.images"
        :product="product"
      />
      <div class="wysiwyg mt-3 product-content">
        <more-info :description="product.description" :shortDesc="true" />
      </div>
    </div>
    <div class="col-lg-7">
      <div class="row vld-parent">
        <loading :active="isLoading" :is-full-page="false" :background-color="'#cbcbcb'">
          <spinner :spinner="isLoading" :is-not-full-page="true">
            Se incarca pasii urmatori...

            <template v-slot:noitems>Nu mai sunt produse de incarcat</template>
          </spinner>
        </loading>

        <div class="col-lg-8 col-md-12">
          <custom-hoch-steps />
        </div>
        <div class="col-md-4">
          <custom-hoch-price />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomHochSteps from "@/components/product_page/custom_hoch/CustomHochSteps.vue";
import CustomHochPrice from "@/components/product_page/custom_hoch/CustomHochPrice.vue";
import Carousel from "@/components/product_page/Carousel.vue";
import BriefInfo from "@/components/product_page/BriefInfo.vue";
import MoreInfo from "@/components/product_page/MoreInfo.vue";
import ProductCarouselMobile from "@/components/product_page/ProductCarouselMobile.vue";
import Spinner from "@/components/blocks/Spinner.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    CustomHochSteps,
    CustomHochPrice,
    Carousel,
    BriefInfo,
    MoreInfo,
    ProductCarouselMobile,
    Spinner,
    Loading,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    product() {
      return this.$store.getters["shop/product"];
    },
    isLoading() {
      return this.$store.getters["configurator/loading"];
    },
  },
};
</script>
