<template>
  <div v-if="includedProducts">
    <!-- First modal -->
    <vue-final-modal
      v-model="modal"
      classes="modal-container"
      content-class="modal-content"
      id="ajax-add-to-cart"
      name="ajax-add-to-cart"
    >
      <button
        type="button"
        class="close modal__close"
        data-dismiss="modal"
        aria-label="Close"
        v-on:click="openModal(false)"
      >
        <font-awesome-icon icon="times-circle" />
      </button>
      <div class="">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="ajax-modal-title">Produsul a fost adaugat in cos</div>
            <div class="row prod-ajax-wrapper prod-ajax-info">
              <div class="ajax-img-wrapper col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <img :src="product.preview_image" alt="" />
              </div>
              <div class="ajax-prod-details col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div
                  v-if="productName"
                  class="modal-titlu-produs"
                  style="margin-bottom: 3px; margin-top: 10px"
                >
                  {{ productName }}
                </div>
                <div class="">
                  <label class="ajax-label">Cantitate:</label>
                  <div class="ajax-value quantity modal-cantitate-produs">1</div>
                </div>
                <div class="">
                  <label class="ajax-label">Pret produs:</label>
                  <div class="ajax-value price modal-pret-produs">
                    {{ pret.total_promo > 0 ? pret.total_promo : pret.total }}
                  </div>
                </div>
              </div>
            </div>
            <div class="prod-ajax-wrapper">
              <div class="ajax-prod-details col-md-6 col-sm-6 col-xs-6">
                <div class="row">
                  <h4></h4>
                </div>
              </div>
            </div>
            <div class="row produse-aditionale-row">
              <label class="col-12 ajax-label produse-aditionale-popup-title"
                >Sistemul de coș include:</label
              >
            </div>
            <div class="row produse-aditionale-row">
              <div id="produse-aditionale" class="col-12">
                <div
                  v-for="includedProduct in includedProducts"
                  v-bind:key="includedProduct"
                >
                  <span><strong>x{{includedProduct.quantity}}</strong> {{ includedProduct.name }}</span
                  ><span>{{ includedProduct.price_promo > 0 ? (includedProduct.quantity*includedProduct.price_promo.split(".")[0]) : (includedProduct.quantity*includedProduct.price.split(".")[0]) }} lei</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 subtotal-mini">
                <label class="ajax-label titlu-pret-modal">Preț total sistem:</label>
                <!-- <span class="s_products">( -->
                <!-- )</span> -->
                <span class="s_price pret-total-modal">{{ pret.total_promo > 0 ? pret.total_promo : pret.total }} lei</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12" style="margin-top: 10px">
            <button
              type="button"
              class="btn btn-orange"
              data-dismiss="modal"
              v-on:click="openModal(false)"
            >
              Continua cumparaturile</button
            ><font-awesome-icon icon="angle-left" />
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <router-link :to="{ name: 'Cart' }" class="btn btn-red final-order"
              ><font-awesome-icon icon="angle-right" />Spre finalizare
              comanda</router-link
            >
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { useStore } from "vuex";
// import { ref, computed} from "vue";
import { computed, inject } from "vue";
export default {
  setup() {
    const { state, commit } = useStore();

    const modal = inject("modal");
    const pret = inject("price");
    const productName = inject("productName");
    const openModal = inject("openModal");

    const includedProducts = computed(() => {
      var included = state.configurator.includedProducts;
      return included;
    });

    return {
      includedProducts,
      state,
      commit,
      modal,
      pret,
      productName,
      openModal,
    };
  },
  watch: {
    modal(val) {
      var mainElement = document.getElementById('bottom-wizard');
      if(val) {
        mainElement.classList.add('z-index-99');
      } else {
        setTimeout(() => {
          mainElement.classList.remove('z-index-99');
        }, 200);
      }
    }
  },
  computed: {
    product() {
      return this.$store.getters["shop/product"];
    },
    cart() {
      return this.$store.getters["shop/cartData"];
    },
  },
};
</script>

<style scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem 1rem 0 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 440px;
  overflow: auto;
}
.modal__title {
  margin: 0 2rem 0.5rem 0;
  font-weight: 700;
  border-bottom: 1px solid #333;
  font-size: 16px;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.ajax-img-wrapper img {
  max-width: 100%;
}

.ajax-prod-details h4 {
  font-size: 18px;
  line-height: 1.1;
  margin: 10px 0;
}

.ajax-prod-details > div {
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 14px;
}

.ajax-label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  padding-right: 10px;
}

.ajax-minicart {
  border-left: 1px solid #ddd;
  background-color: #f3f3f3;
}

#ajax-add-to-cart .modal-dialog {
  width: 920px;
  width: 440px;
  margin: 110px auto;
  padding: 0 10px;
}

label.ajax-label {
  float: left;
  padding-right: 10px;
}
.ajax-modal-title {
  font-weight: bold;
  border-bottom: 1px solid #333;
  font-size: 16px;
}

.subtotal-mini {
  padding: 15px;
  align-items: center;
  font-size: 14px;
}

.s_title,
.s_price,
.s_products {
  letter-spacing: -0.5px;
}

.s_products {
  font-family: Montserrat Light;
  font-size: 11px;
}

.s_price {
  margin-left: auto;
  display: block;
  float: right;
}

#ajax-add-to-cart .btn {
  width: 100%;
  margin-bottom: 15px;
  padding: 11px 0;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
}

.btn.btn-orange {
  background-color: #141619;
  color: #fff;
  font-weight: bold;
  border-radius: 0;
  padding: 6px 30px;
}

#ajax-add-to-cart .fa-angle-left {
  position: absolute;
  left: 30px;
  top: 22%;
  color: #fff;
  font-size: 20px;
}

#ajax-add-to-cart .fa-angle-right {
  position: absolute;
  right: 30px;
  top: 22%;
  color: #fff;
  font-size: 20px;
}

.final-order {
  background-color: #008c00 !important;
}

#ajax-add-to-cart button.close {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #000 !important;
  z-index: 10;
  opacity: 1;
  width: 30px;
  height: 30px;
  font-size: 20px;
  background-color: transparent;
}

.ajax-label {
  display: inline-block;
  clear: right;
  cursor: default;
  /* font-size: 16px; */
  font-weight: bold;
  color: #9d0000;
  padding-right: 5px;
}

.produse-aditionale-popup-title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  color: #000000 !important;
  text-transform: uppercase;
}

#produse-aditionale > div:first-child {
  border-top: solid 1px #979797;
}

#produse-aditionale > div {
  line-height: 28px;
  border-bottom: solid 1px #979797;
  display: inline-block;
  width: 100%;
  position: relative;
}

#produse-aditionale > div > span:first-child {
  font-family: Montserrat;
  font-size: 12px;
  color: #636363;
  float: left;
  /* padding-right: 50px; */
  display: inline-block;
  width: calc(100% - 44px);
}

#produse-aditionale > div > span:last-child {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  float: right;
  bottom: 0px;
  position: absolute;
  right: 0;
}

.prod-ajax-info {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media and (max-width: 576px) {
  #ajax-add-to-cart .fa-angle-left {
    left: 25px;
    top: 19.5%;
  }
}
</style>

<style scoped>
.dark-mode div ::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
