<template>
    <div class="sticla_wrp" :class="classes">
        <div :id="id">
            <img :src="image">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['classes', 'id', 'image']
}
</script>

<style lang="scss" scoped>

.sticla_wrp {
    height: auto;
    margin-top: 15px;
}

div#img-animate, div#img-animate-2 {
    width: 100%;
    position: relative;

    img {
        width: 100%;
    }
}

#calc-usa  {

    div#img-animate, div#img-animate-2 {

        border: 1px solid #999;
        padding: 15px 15px 30px 30px;
        background-color: #ECECEC;

    } 
}

.main-content img {
    max-width: 100%;
}


</style>