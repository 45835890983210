<template>
    <div class="rezultat hide-price">
        <span class="rezultat-txt">Sticla termorezistenta 4mm:</span>
        <!-- <span v-if="!isComplete" class="rezultat-required"
            >Vă rugăm să completați dimensiunile!</span
    > -->
        <span id="rezultat-lei">{{ isComplete ? "PRET: " + calcPrice : 0 }} lei</span>
        <input type="hidden" name="total" id="total" value="" maxlength="10" />
    </div>
</template>

<script>
export default {
    props: ["isComplete", "width", "height"],

    computed: {
        productPrice() {
            let product = this.$store.getters["shop/product"];

            let product_price = {};
            if (product.layout_property) {
                product_price = product.layout_property.find((item) => {
                    return item.code === "product-price";
                });
            }
            return product_price.value;
        },
        productTransport() {
            let product = this.$store.getters["shop/product"];

            let transport_price = {};
            if (product.layout_property) {
                transport_price = product.layout_property.find((item) => {
                    return item.code === "transport-price";
                });
            }
            return transport_price.value;
        },
        calcPrice() {
            let product = this.$store.getters["shop/product"];
            let price = Math.floor(
                ((this.width * this.height) / 1000000) * this.productPrice +
                    Math.floor(this.productTransport)
            );

            let productLayout = product.layout_property.find((item) => {
                return item.code === "layout";
            });

            switch (productLayout.value) {
                case "custom-glass":
                    this.$store.dispatch("glass/setPrice", price);
                    break;
                case "custom-door":
                    price = price < 2475 ? 2475 : price;
                    this.$store.dispatch("door/setPrice", price);
                    break;
            }

            this.$gtm.trackEvent({
                event: "Calculator",
                category: "Calculator",
                label:
                    product.name +
                    " - " +
                    this.width +
                    "x" +
                    this.height +
                    " : " +
                    price +
                    " lei",
                value: this.width + "x" + this.height + " : " + price + " lei",
            });

            return price;
        },
    },
};
</script>

<style lang="scss" scoped>
.rezultat {
    font-size: 12px;
    text-shadow: 1px 1px 2px #fff;

    span.rezultat-required {
        font-size: 1.1em;
        font-weight: 700;
        color: #ff1300;
    }

    span#rezultat-lei {
        font-family: Montserrat Bold;
        font-size: 24px;
        text-transform: uppercase;
        display: block;
    }
}
</style>
